// assets
import { IconApps, IconPhotoEdit } from '@tabler/icons';

// constant
const icons = {
    IconApps,
    IconPhotoEdit
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const appsettings = {
    id: 'appsettings',
    title: 'App Settings',
    type: 'group',
    children: [
        {
            id: 'app_settings',
            title: 'Settings',
            type: 'item',
            url: '/appsettings/appsettings',
            icon: icons.IconApps,
            breadcrumbs: false
        },
        {
            id: 'app_images',
            title: 'Images',
            type: 'item',
            url: '/appsettings/appimages',
            icon: icons.IconPhotoEdit,
            breadcrumbs: false
        }
    ]
};

export default appsettings;
