import { useRoutes } from 'react-router-dom';
import { lazy } from 'react';

// routes
import MainRoutes from './MainRoutes';
import LoginRoutes from './LoginRoutes';
import Loadable from 'ui-component/Loadable';
const ErrorPage = Loadable(lazy(() => import('views/maintenance/index')));

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([LoginRoutes, MainRoutes, { path: '*', element: <ErrorPage /> }]);
}
