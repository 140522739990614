import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const AppDashboard = Loadable(lazy(() => import('views/dashboard/App')));
const DashboardEdit = Loadable(lazy(() => import('views/dashboard/App/dashboardedit')));
const AnimeEdit = Loadable(lazy(() => import('views/dashboard/App/AnimeEdit')));
const AnimeSearch = Loadable(lazy(() => import('views/dashboard/App/AnimeSearch')));

// utilities routing
const UserList = Loadable(lazy(() => import('views/userlist')));
const Notification = Loadable(lazy(() => import('views/Notification')));
const AppSettings = Loadable(lazy(() => import('views/AppSettings')));
const AppImages = Loadable(lazy(() => import('views/AppSettings/AppImages')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: 'dashboard',
            children: [
                {
                    path: 'default',
                    element: <DashboardDefault />
                },
                {
                    path: 'appdashboard',
                    element: <AppDashboard />
                },
                {
                    path: 'dashboardedit',
                    element: <DashboardEdit />
                },
                {
                    path: 'animesearch',
                    element: <AnimeSearch />
                },
                {
                    path: 'animeedit',
                    element: <AnimeEdit />
                }
            ]
        },
        {
            path: 'list',
            children: [
                {
                    path: 'user-list',
                    element: <UserList />
                },
                {
                    path: 'notification',
                    element: <Notification />
                }
            ]
        },
        {
            path: 'appsettings',
            children: [
                {
                    path: 'appsettings',
                    element: <AppSettings />
                },
                {
                    path: 'appimages',
                    element: <AppImages />
                }
            ]
        }
    ]
};

export default MainRoutes;
