// action - state management
import * as actionTypes from './actions';

const initialState = {};

export const userLoginReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_USER_LOGIN_DATA:
            return {
                ...state,
                userData: action.payload
            };
        default:
            return state;
    }
};
