import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import { userLoginReducer } from './UserLoginReducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    customization: customizationReducer,
    userLogin: userLoginReducer
});

export default reducer;
