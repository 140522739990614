// assets
import { IconTypography, IconPalette, IconShadow, IconWindmill, IconClipboardList, IconNotification } from '@tabler/icons';

// constant
const icons = {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill,
    IconClipboardList,
    IconNotification
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const utilities = {
    id: 'list',
    title: 'List',
    type: 'group',
    children: [
        {
            id: 'user_list',
            title: 'Users List',
            type: 'item',
            url: '/list/user-list',
            icon: icons.IconClipboardList,
            breadcrumbs: false
        },
        {
            id: 'notification',
            title: 'Notification',
            type: 'item',
            url: '/list/notification',
            icon: icons.IconNotification,
            breadcrumbs: false
        }
    ]
};

export default utilities;
