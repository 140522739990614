import dashboard from './dashboard';
import utilities from './utilities';
import appsettings from './appsettings';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, utilities, appsettings]
};

export default menuItems;
