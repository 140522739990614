import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Avatar, Box, ButtonBase, Card, Grid, InputAdornment, OutlinedInput, Popper, TextField, useMediaQuery } from '@mui/material';

// third-party
import PopupState, { bindPopper, bindToggle } from 'material-ui-popup-state';
import { MENU_OPEN, SET_MENU } from 'store/actions';

// project imports
import Transitions from 'ui-component/extended/Transitions';

// assets
import { IconSearch, IconX } from '@tabler/icons';
import { shouldForwardProp } from '@mui/system';
import Autocomplete from '@mui/material/Autocomplete';
import menuItem from 'menu-items';
// styles
const PopperStyle = styled(Popper, { shouldForwardProp })(({ theme }) => ({
    zIndex: 1100,
    width: '99%',
    top: '-55px !important',
    padding: '0 12px',
    [theme.breakpoints.down('sm')]: {
        padding: '0 10px'
    }
}));

const OutlineInputStyle = styled(OutlinedInput, { shouldForwardProp })(({ theme }) => ({
    width: 434,
    marginLeft: 16,
    paddingLeft: 16,
    paddingRight: 16,
    '& input': {
        background: 'transparent !important',
        paddingTop: '15.5px !important',
        paddingLeft: '4px !important'
    },
    [theme.breakpoints.down('lg')]: {
        width: 250
    },
    [theme.breakpoints.down('md')]: {
        width: '100%',
        marginLeft: 4,
        background: '#fff'
    }
}));

const HeaderAvatarStyle = styled(Avatar, { shouldForwardProp })(({ theme }) => ({
    ...theme.typography.commonAvatar,
    ...theme.typography.mediumAvatar,
    background: theme.palette.secondary.light,
    color: theme.palette.secondary.dark,
    '&:hover': {
        background: theme.palette.secondary.dark,
        color: theme.palette.secondary.light
    }
}));

const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: theme.palette.primary[800],
    backgroundColor: '#d0dff7'
}));

const GroupItems = styled('ul')({
    padding: 0
});

// ==============================|| SEARCH INPUT - MOBILE||============================== //

const MobileSearch = ({ value, setValue, popupState }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const matchesSM = useMediaQuery(theme.breakpoints.down('lg'));

    const itemHandler = (id) => {
        dispatch({ type: MENU_OPEN, id });
        if (matchesSM) dispatch({ type: SET_MENU, opened: false });
    };

    // active menu item on page load
    useEffect(() => {
        const currentIndex = document.location.pathname
            .toString()
            .split('/')
            .findIndex((id) => id === value['id']);
        if (currentIndex > -1) {
            dispatch({ type: MENU_OPEN, id: value['id'] });
        }
        // eslint-disable-next-line
    }, []);

    const handleOnClick = (params) => {
        setValue(params);
        itemHandler(params['id']);
        navigate(params['url']);
    };

    const lit = [];
    menuItem.items.map((option) => {
        return option.children.map((option1) => {
            lit.push({
                grouptitle: option.title,
                ...option1
            });
        });
    });

    return (
        <Grid item xs={12}>
            <Autocomplete
                id="combo-box-demo"
                //options={menuItem.items}
                options={lit}
                groupBy={(option) => option.grouptitle}
                getOptionLabel={(option) => option.title}
                forcePopupIcon={false}
                disableClearable
                freeSolo
                onChange={(e, value) => {
                    handleOnClick(value);
                }}
                renderGroup={(params) => (
                    <li key={params.key}>
                        <GroupHeader>{params.group}</GroupHeader>
                        <GroupItems>{params.children}</GroupItems>
                    </li>
                )}
                renderInput={
                    (params) => (
                        <TextField
                            {...params}
                            label="Search"
                            placeholder="Search"
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <IconSearch stroke={1.5} size="1rem" color={theme.palette.grey[500]} />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Box sx={{ ml: 2 }}>
                                            <ButtonBase sx={{ borderRadius: '12px' }}>
                                                <Avatar
                                                    variant="rounded"
                                                    sx={{
                                                        ...theme.typography.commonAvatar,
                                                        ...theme.typography.mediumAvatar,
                                                        background: theme.palette.orange.light,
                                                        color: theme.palette.orange.dark,
                                                        '&:hover': {
                                                            background: theme.palette.orange.dark,
                                                            color: theme.palette.orange.light
                                                        }
                                                    }}
                                                    {...bindToggle(popupState)}
                                                >
                                                    <IconX stroke={1.5} size="1.3rem" />
                                                </Avatar>
                                            </ButtonBase>
                                        </Box>
                                    </InputAdornment>
                                )
                            }}
                        />
                    )
                    // <OutlineInputStyle
                    //     {...params}
                    //     id="input-search-header"
                    //     value={value}
                    //     onChange={(e) => setValue(e.target.value)}
                    //     placeholder="Search"
                    //     startAdornment={
                    //         <InputAdornment position="start">
                    //             <IconSearch stroke={1.5} size="1rem" color={theme.palette.grey[500]} />
                    //         </InputAdornment>
                    //     }
                    //     endAdornment={
                    //         <InputAdornment position="end">
                    //             <Box sx={{ ml: 2 }}>
                    //                 <ButtonBase sx={{ borderRadius: '12px' }}>
                    //                     <Avatar
                    //                         variant="rounded"
                    //                         sx={{
                    //                             ...theme.typography.commonAvatar,
                    //                             ...theme.typography.mediumAvatar,
                    //                             background: theme.palette.orange.light,
                    //                             color: theme.palette.orange.dark,
                    //                             '&:hover': {
                    //                                 background: theme.palette.orange.dark,
                    //                                 color: theme.palette.orange.light
                    //                             }
                    //                         }}
                    //                         {...bindToggle(popupState)}
                    //                     >
                    //                         <IconX stroke={1.5} size="1.3rem" />
                    //                     </Avatar>
                    //                 </ButtonBase>
                    //             </Box>
                    //         </InputAdornment>
                    //     }
                    //     aria-describedby="search-helper-text"
                    //     inputProps={{ 'aria-label': 'weight' }}
                    // />
                }
            />
        </Grid>
    );
};

MobileSearch.propTypes = {
    value: PropTypes.string,
    setValue: PropTypes.func,
    popupState: PopupState
};

// ==============================|| SEARCH INPUT ||============================== //

const SearchSection = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const matchesSM = useMediaQuery(theme.breakpoints.down('lg'));
    const [value, setValue] = useState('');

    const itemHandler = (id) => {
        dispatch({ type: MENU_OPEN, id });
        if (matchesSM) dispatch({ type: SET_MENU, opened: false });
    };

    // active menu item on page load
    useEffect(() => {
        const currentIndex = document.location.pathname
            .toString()
            .split('/')
            .findIndex((id) => id === value['id']);
        if (currentIndex > -1) {
            dispatch({ type: MENU_OPEN, id: value['id'] });
        }
        // eslint-disable-next-line
    }, []);

    const handleOnClick = (params) => {
        setValue(params);
        itemHandler(params['id']);
        navigate(params['url']);
    };

    const lit = [];
    const options = menuItem.items.map((option) => {
        return option.children.map((option1) => {
            lit.push({
                grouptitle: option.title,
                ...option1
            });
        });
    });

    return (
        <>
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <PopupState variant="popper" popupId="demo-popup-popper">
                    {(popupState) => (
                        <>
                            <Box sx={{ ml: 2 }}>
                                <ButtonBase sx={{ borderRadius: '12px' }}>
                                    <HeaderAvatarStyle variant="rounded" {...bindToggle(popupState)}>
                                        <IconSearch stroke={1.5} size="1.2rem" />
                                    </HeaderAvatarStyle>
                                </ButtonBase>
                            </Box>
                            <PopperStyle {...bindPopper(popupState)} transition>
                                {({ TransitionProps }) => (
                                    <>
                                        <Transitions type="zoom" {...TransitionProps} sx={{ transformOrigin: 'center left' }}>
                                            <Card
                                                sx={{
                                                    background: '#fff',
                                                    [theme.breakpoints.down('sm')]: {
                                                        border: 0,
                                                        boxShadow: 'none'
                                                    }
                                                }}
                                            >
                                                <Box sx={{ p: 2 }}>
                                                    <Grid container alignItems="center" justifyContent="space-between">
                                                        <Grid item xs>
                                                            <MobileSearch value={value} setValue={setValue} popupState={popupState} />
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Card>
                                        </Transitions>
                                    </>
                                )}
                            </PopperStyle>
                        </>
                    )}
                </PopupState>
            </Box>
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                {/* <OutlineInputStyle
                    id="input-search-header"
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    placeholder="Search"
                    startAdornment={
                        <InputAdornment position="start">
                            <IconSearch stroke={1.5} size="1rem" color={theme.palette.grey[500]} />
                        </InputAdornment>
                    }
                    aria-describedby="search-helper-text"
                    inputProps={{ 'aria-label': 'weight' }}
                /> */}
                <Autocomplete
                    id="combo-box-demoo"
                    options={lit}
                    groupBy={(option) => option.grouptitle}
                    getOptionLabel={(option) => option.title}
                    sx={{ width: 300, ml: 5 }}
                    freeSolo
                    onChange={(e, value) => {
                        handleOnClick(value);
                    }}
                    renderGroup={(params) => (
                        <li key={params.key}>
                            <GroupHeader>{params.group}</GroupHeader>
                            <GroupItems>{params.children}</GroupItems>
                        </li>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Search"
                            placeholder="Search"
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <IconSearch stroke={1.5} size="1rem" color={theme.palette.grey[500]} />
                                    </InputAdornment>
                                )
                            }}
                        />
                    )}
                />
            </Box>
        </>
    );
};

export default SearchSection;
